import React from "react";

export default function Dmca() {
  const email = "dmca@richcams.com";
  const SITE_NAME = "RichCams";

  return (
    <section className="cookie">
      <h1>DMCA Complaints/Content Removal Policy</h1>
      <p>
        Filling this page correctly will make your take-down notice complaint with the Digital
        Millenium Copyright Act and allow us to address it expeditiously. Note that the information
        provided in this legal notice will be forwarded to the person who provided the allegedly
        infringing content.
      </p>

      <h3>Copyright and Intellectual Property Policy</h3>
      <p>
        the intellectual property rights of others, and we ask our business partners to do the same.
        Material available on or through other websites may be protected by copyright and the
        intellectual property laws of the European Union and/or other countries (including United
        States). It is our policy, in appropriate circumstances and at our discretion, to remove
        infringing material and disable and/or terminate the accounts of users who may infringe or
        repeatedly infringe the copyrights or other intellectual property
      </p>
      <h3>Notice of Claimed Infringement</h3>
      <p>
        If you are a copyright owner or an agent thereof and believe that any content on {SITE_NAME}{" "}
        infringes upon your copyrights, you may submit a notification pursuant to the Digital
        Millennium Copyright Act (“DMCA”).
      </p>
      <p>
        DMCA Complain should contain the following information (see 17 U.S.C 512(c)(3)) for further
        detail:
      </p>
      <ol className="decimal">
        <li>
          A physical or electronic signature of the copyright owner or a person authorized to act on
          their behalf;
        </li>
        <li>An identification of the copyrighted work claimed to have been infringed;</li>
        <li>
          A description of the nature and exact location of the material that you claim to infringe
          your copyright, in sufficient detail to find and positively identify that material. For
          example, we require a link to the specific post that contains the material and a
          description of which specific portion of the post – an image, a link, the text, etc. your
          complaint refers to;
        </li>
        <li>Your name, telephone number and email address;</li>
        <li>
          A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or the law;
        </li>
        <li>
          A statement that the information in the notification is accurate, and under penalty of
          perjury, that you are authorized to act on behalf of the owner of an exclusive right that
          is allegedly infringed.
        </li>
      </ol>
      <p>
        {SITE_NAME} designated Copyright Agent to receive notifications of claimed infringement is:{" "}
        <a href={`mailto:${email}`}>{email}</a> You acknowledge that if you fail to comply with all
        these requirements, your DMCA notice may not be valid.
      </p>
      <p>
        Complaints will be reviewed and resolved within seven (7) business days. That resolution
        will then be communicated to the Complainant.
      </p>

      <h2>1. Take Down Procedure</h2>
      <p>
        Our site implements the following “notification and takedown” procedure upon receipt of any
        notification of claimed copyright infringement. Our site reserves the right at any time to
        disable access to, or remove any material or activity accessible on or from any site or any
        materials claimed to be infringing or based on facts or circumstances from which infringing
        activity is apparent. It is the firm policy of our site to terminate the account of repeat
        copyright infringers, should such an account exist, when appropriate, and our site will act
        expeditiously to remove access to all material that infringes on another’s copyright,
        according to the procedure set forth in 17 U.S.C. §512 of the “DMCA”. Our site’s DMCA Notice
        Procedures are set forth in the preceding paragraph. If the notice does not comply with §512
        of the DMCA, but does comply with three requirements for identifying sites that are
        infringing according to §512 of the DMCA, our site shall attempt to contact or take other
        reasonable steps to contact the complaining party to help that party comply with the notice
        requirements. When the Designated Agent receives a valid notice, our site will expeditiously
        remove and/or disable access to the infringing material and shall notify the affected user.
      </p>
      <p>
        Then, the affected user may submit a counter-notification to the Designated Agent containing
        a statement made under penalty of perjury that the user has a good faith belief that the
        material was removed because of misidentification of the material. After the Designated
        Agent receives the counter-notification, it will replace the material at issue within 10-14
        days after receipt of the counter-notification unless the Designated Agent receives notice
        that a court action has been filed by the complaining party seeking an injunction against
        the infringing activity. Our site reserves the right to modify, alter or add to this policy,
        and all users should regularly check back to these Terms and Conditions to stay current on
        any such changes.
      </p>

      <h2>2. Abuse Notification</h2>
      <p>
        Abusing the DMCA Notice procedures set forth above, or misrepresenting facts in a DMCA
        Notice or Counter-notification, can result in legal liability for damages, court costs and
        attorneys fees under U.S. federal law. See; 17 U.S.C. §512(f). This Notice and Takedown
        Procedures only apply to claims of copyright infringement by copyright holders and their
        agents – not to any other kind of abuse, infringement or legal claim. We will investigate
        and take action against anyone abusing the DMCA notification or counter-notification
        procedure. Please ensure that you meet all of the legal qualifications before submitting a
        DMCA Notice to our Designated Agent.
      </p>

      <h2>3. Counter-Notice Procedure:</h2>
      <p>
        If the Recipient of a Notice of Claimed Infringement (“Notice”) believes that the Notice is
        erroneous or false, and/or that allegedly infringing material has been wrongly removed in
        accordance with the procedures outlined above, the Recipient is permitted to submit a
        counter-notification pursuant to Section 512(g)(2)&(3) of the DMCA. A counter-notification
        is the proper method for the Recipient to dispute the removal or disabling of material
        pursuant to a Notice. The information that a Recipient provides in a counter-notification
        must be accurate and truthful, and the Recipient will be liable for any misrepresentations
        which may cause any claims to be brought against our site relating to the actions taken in
        response to the counter-notification.
        <br />
        To submit a counter-notification, please provide Our Designated Copyright agent the
        following information:
      </p>
      <ol className="decimal">
        <li>
          a specific description of the material that was removed or disabled pursuant to the
          Notice;
        </li>
        <li>
          a description of where the material was located within the our site or the content before
          such material was removed and/or disabled (preferably including specific url’s associated
          with the material);
        </li>
        <li>
          a statement reflecting the Recipient’s belief that the removal or disabling of the
          material was done so erroneously. For convenience, the following format may be used: “I
          swear, under penalty of perjury, that I have a good faith belief that the referenced
          material was removed or disabled by the service provider as a result of mistake or
          misidentification of the material to be removed or disabled.”
        </li>
        <li>the Recipient’s physical address, telephone number, and email address;</li>
        <li>
          a statement that the Recipient consents to the jurisdiction of the Federal District Court
          in and for the judicial district where the Recipient is located, or if the Recipient is
          outside of the United States, for any judicial district in which the service provider may
          be found, and that the Recipient will accept service of process from the person who
          provided the Notice, or that person’s agent.
        </li>
      </ol>
      <p>
        Notification containing the above information must be signed and sent to:{" "}
        <a href="mailto:dmca@richcams.com">dmca@richcams.com</a>
      </p>

      <h2>4. Please do not send other inquires or information to our Designated Agent</h2>
      <p>
        After receiving a DMCA-compliant counter-notification, Our Designated Copyright Agent will
        forward it to Us, and We will then provide the counter-notification to the claimant who
        first sent the original Notice identifying the allegedly infringing content.
        <br />
        Thereafter, within ten to fourteen (10-14) days of our receipt of a counter-notification, We
        will replace or cease disabling access to the disputed material provided that we or our
        Designated Copyright Agent have not received notice that the original claimant has filed an
        action seeking a court order to restrain the Recipient from engaging in infringing activity
        relating to the material on our site’s system or network.
      </p>

      <h2>5. Repeat Infringers:</h2>
      <p>
        It is our policy to terminate access to our Website and revoke privileges of any person who
        we determine to be a "repeat infringer." Repeat infringement shall be determined in our sole
        and exclusive discretion but will include any individual who has been the subject of more
        than one copyright infringement notice.
      </p>
      <p>
        Nothing in this policy waives any other right we may have to pursue copyright infringers or
        any other individual who uses our Website to violate the intellectual property rights of
        others. We will not indemnify any alleged infringer in any manner.
      </p>
    </section>
  );
}
