import React from "react";
import imgQuestion from "../../images/icons/question.svg";
import { useTranslation } from "react-i18next";

//CusRadio - дефолтный компонент radio
// options - возможные поля для выбора следующего вида :
//  [{
//     title - название которое будет отображаться пользователю
//     value - значение которое передастся в htmp input
//     info  - дополнительный блок информации с каким то текстом
//  }]
export default function CusRadio({ name, options, legend, onChange }) {
  const { t } = useTranslation();

  return (
    <fieldset className="custom-radio">
      {legend && <legend> {t(legend)} </legend>}

      {options?.map((option, indx) => (
        <div key={option.value}>
          <input
            onChange={onChange}
            type="radio"
            id={option.value}
            name={name}
            value={option.value}
            defaultChecked={indx === 0}
          />
          <label htmlFor={option.value}>{t(option.title)}</label>
          <div className="custom-radio__info">
            <img src={imgQuestion} alt="help" />
            <span className="custom-radio__info__text">{t(option.info)}</span>
          </div>
        </div>
      ))}
    </fieldset>
  );
}
