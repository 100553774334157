import React from "react";

export default function TermsContentEn({ isSection }) {
  const SomeText = () => (
    <>
      {" "}
      <h1>Content Provider Agreement (Terms & Conditions)</h1>
      <pre>
        {`This agreement (hereinafter referred to as the “Agreement”) governs the relationship between:

The Broadcaster, Creator, Content Provider, Model, Studio and/or the Studio (hereinafter referred to as “Performer”, “me”, “you” or “I”) AND KronaSoft OÜ Harju maakond, Tallinn, Pirita linnaosa, Padriku tee T6, 11912 (hereinafter referred to as the “RichCams”).

By filling in and submitting the sign-up form, by checking boxes “I, the undersigned, fully agree to the Content Provider Agreement (Terms & Conditions)”, the Performer gives her(his)/its consent to, and notably express consent to the clauses regarding Declarations (4th clause) and Personal data (5th clause), and accepts the following Agreement.`}
      </pre>
      <h3>Glossary</h3>
      <pre>{`“Mode / Performer”: A person providing adult entertainment Services for the RichCams and who created an Account of the Model.
“Studio”: A person (legal or natural) subscribing to a Studio Account, and who is providing adult entertainment Services on its own or while contracting with Studio Models.
“Studio Model”: Model registered under an Account of a Studio and providing Services on the Studio’s behalf.
“Account”: A registration account for the Model and/or for the Studio on Start.RichCams.com.
“Services”: Services rendered by the Performer on the Websites and, as the case may be, the transmission and distribution of such content to the public (i.e., broadcasting). Such services namely comprise adult entertainment Services, more precisely performing live webcam shows, chat in front of cameras and uploading original content for the entertainment of Members all over the world, who have selected the Performer according to live streams, images, offline (teaser) and paid videos, and within the form of online live streaming video shows, broadcasting and chat sessions usually sold on a pay-per-minutes basis.
“Websites”: Different websites, including RichCams.com and any other website which might be operated by the RichCams.
“Members”: Persons visiting and/or buying the Services on the Websites or receiving any of its content via any media available now or created in the future (also referred to as “members” or “end users” from time to time).
`}</pre>
      <h2>1. PURPOSE</h2>
      <pre>
        Natural or legal Performers are acting under this Agreement as providers of professional
        Services in the frame of their business/professional activities when rendering their
        Services (not consumers).
      </pre>
      <h3>MODEL / PERFORMER</h3>
      <pre>
        {`Section “MODEL / PERFORMER” is applicable only to Performers registered under a Model Account.

By means of the present Agreement, I expressly mark my interest to render Services as a Performer on the Websites promoted by the RichCams and its affiliates, which currently manages a business that markets products/services to consumers (B2C), which may include but is not limited to interactive live streaming video chat site (based on the one available at`}{" "}
        <a href="https:///RichCams.com" target="_blank" rel="noreferrer">
          RichCams.com
        </a>{" "}
        {`) and broadcasting, according to the terms of this Agreement.

The Performer undertakes not to provide any illegal, harmful, obscene, hateful, racial, ethnic or any other objectionable content (such as defamatory, abusive, threatening, racially offensive, etc.) while rendering the Services under this Agreement.

I hereby certify that I work independently and that the RichCams do not possess any power of direction, subordination, or other kind of authority over me. Consequently, the present Agreement does not determine my place or schedule for rendering the Services. The RichCams do not tell me what my lawful content or my Services will be, since I am fully aware, as a Performer, that I must provide reliable and efficient Services in full compliance with the applicable legislation and object of the present Agreement.

I hereby certify that I have read and understood the terms and conditions of the RichCams that are applicable for Members of the Websites and I am aware of the level of quality and obligations that are implied in such terms and conditions. In the case of any questions to this regard, I can contact the RichCams according to the contact details provided in this Agreement.

Performers may render Services in one or more of the areas/categories available on the Websites, which are subject to change without prior notice from the RichCams, with respect to standard requirements related to the Websites environment and consumer experience intended by the RichCams.

The Services will be rendered by me with total autonomy and technical independence. Therefore, I am completely responsible and liable for my own actions, videos, audio, chat, dialogue, and advice, as a consenting responsible adult, and all decisions related to the content of my actions as a Performer are made at my own discretion.

Notwithstanding the above, I am acting under this Agreement as a professional when rendering the Services. Nevertheless it is not intended that the Services rendered by me are to be viewed as being certified by public authorities or by any necessary accreditations, it being my responsibility to keep the end users duly aware of that fact. I understand that I am not in any way an employee of the RichCams and/or of any other entity related to the RichCams.
`}
      </pre>
      <h3>STUDIO</h3>
      <pre>{`Section “STUDIO” is only applicable to Performers registered under a Studio Account.

The Studio enters into this Agreement with the intent to provide Services to the RichCams. For this purpose, the Studio may contract with studio models. It is not our responsibility to limit the scope of liability for third parties, especially given the content of the next two paragraphs.

The Studio understands and agrees that the Services to be rendered by Studio Models (hereafter referred to as the “Studio Models Services”) under the terms and conditions of the contract concluded between the Studio and Studio Models (hereafter referred to as the “Studio Model Contract”) are of adult entertainment nature, namely, to perform live webcam shows and chat through the Studio’s equipment for the entertainment of Members all over the world, who have selected such Studio Models according to live streams, images, offline (teaser) and paid videos or receiving the broadcast.

The Studio understands and agrees that it is its responsibility to determine the terms and conditions of the Studio Model Contract, which the Studio will autonomously and independently determine without any influence from the RichCams, provided that such Studio Model Contract does not result in the violation of the Studio’s obligations under the present Agreement and while procuring that Studio Models must obey the rules that would be substantially similar to those of "1. Model / Performer" in this Agreement. Accordingly, Studio Models will not be in a contractual or other relationship of any nature with the RichCams. The Studio agrees that it will remain solely and completely responsible and liable for all the content, actions, shows, videos, chat, dialogue, and any other Studio Models Services rendered under the Studio Model Contract.

I, as a Studio, hereby certify that I work independently and that the RichCams does not possess any power of direction, subordination, or other kind of authority over me. Consequently, this Agreement does not determine the place or schedule for Studio Models to render the Studio Models Services, which are determined in the Studio Model Contract. The RichCams does not tell me what my lawful content or the Studio Models Services will be, since I am fully aware, as a Studio, that I am responsible for Studio Models to render reliable and efficient services in full compliance with the applicable legislation and intended purpose of the Studio Model Contract.

I, as a Studio, hereby certify that I have read and understood the terms and conditions of the Websites that are applicable to Members of the Websites and I am aware of the level of quality and obligations that are provided in such terms and conditions. In the case of any questions to this regard, I can contact the RichCams according to the contact details provided in this Agreement. In respect of this, I hereby certify that I am liable for Studio Models, in compliance with their obligations under the Studio Model Contract, to render the Studio Models Services according to the level of quality and obligations that are provided in the terms and conditions of the Websites that are applicable to Members of the Websites.

Studio is acting under this Agreement as a professional in the frame of the exercise of my business activities (as opposed to consumers) when rendering the Services and thus will be solely responsible for paying all applicable taxes. As a general rule, the Studio is required to provide invoices for all the Services rendered to the RichCams. Such invoices will comply with all applicable state and federal laws, as the case may be. In case the Studio cannot provide an invoice to the RichCams, the latter may generate a self-invoice. By acceptance of these terms and conditions, the Studio agrees to the RichCams operating a self-invoicing system, if needed, and accepts each self-invoice issued by the RichCams in respect of the Services provided by the Studio. It is not intended that the Studio is to be viewed as being certified by public authorities or by any necessary accreditation, it being the Studio’s responsibility to keep the end users duly aware of that fact. As a Studio, I understand that I am not and that Studio Models are not in any way employees of the RichCams nor of any other entity related to the RichCams.

I hereby commit, as a Studio, that under the Studio Model Contract, the Studio Models will render the Studio Models Services with high quality, will not knowingly provide any wrongful or misleading information to the Member of the Websites, and that the Studio Models will be acting honestly and professionally at all times.

The Studio further commits to guaranteeing that Studio Models respect the requirements applicable to the Studios as well as the requirements for Models as provided in "1. Model / Performer" above.`}</pre>
      <h2>2. DURATION</h2>
      <pre>
        This Agreement will be immediately effective on the date that the Performer fills in and
        submits the sign-up form, by checking boxes “I, the undersigned, fully agree to the Content
        Provider Agreement (Terms & Conditions)” and will remain in full force and effect until duly
        terminated by either party in full compliance with the terms and conditions expressly set
        forth in this Agreement.
      </pre>
      <h2>3. FEES</h2>
      <pre>
        {`I acknowledge that the amount to be paid for the Services rendered, within the scope of this Agreement, will be published on my registration Account. The Performer’s registration Account shows all the information related to the Performer’s income from the Services rendered, settlements with the RichCams, statistics and other relevant information.

I understand and accept that the amounts to be paid by RichCams to me will correspond to a certain percentage (varying according to the type of Services) of the chosen charge option (i.e., certain percentage of the price paid by the Members of the Websites to the RichCams) as described above which percentage and charge options will be published on my registration Account and/or my sales page (which I can consult before rendering any Services). I understand and accept that my compensation will be limited to the aforementioned payment and that no royalty will be due to me for any original content uploaded by me to the Websites or for any content of me which is broadcasted. I fully understand and acknowledge that the Members of the Websites are exclusively the customers of RichCams.

I acknowledge that the`}{" "}
        <b>hold</b> payment period for the Services rendered is <b>7 days</b>{" "}
        {", with payments made"} <b>every Monday</b>{" "}
        {"with the following limitations: minimum payment amount is "} <b>$50</b> for Models /
        Studios; if by the end of the payment period a Model’s/Studio’s income is less than the
        above-mentioned amount, their payment will be put on hold until the minimum payment amount
        is reached. I acknowledge that payments to be paid for the Services rendered are issued
        weekly via Crypto <b>USDT TRC20</b>{" "}
        {`. 
    
When withdrawing funds, a commission is charged in the following amount:

USDT TRC20 - 1% + 2 USDT;

I acknowledge that payments to be paid for the Services rendered may be proportionally and adequately reduced in case of my/Studio Models’ unlawful conduct, namely, in violation of this Agreement and/or applicable legislation and/or in case the fines were imposed on me/ Studio Model stated in the “8. BASIC RULES FOR THE PROVISION OF SERVICES” of this Agreement.

The prices payable by Members and which also constitute the base for calculating amounts earned by me as described above are subject to change from time to time at RichCams’s sole discretion and without prior notification to me. In case of substantial change in the price charged to Members, I will have the option to terminate this Agreement when such material changes occur; otherwise continuing to provide Services through the Websites will be considered implicit acceptance of such changes.

    `}
        <b>Credit card fraud is a crime</b>
        {`, and the RichCams will do everything in its power to help local and international authorities to trace people committing such felonies. The RichCams will not pay any fees in case of fraudulent purchases. The purchases must originate from the owner of the credit card or have permission from the owner for using their credit card. The RichCams’s team does everything in its power to minimise the percentage of fraudulent purchases. If you know of anyone committing such activities please notify us, so that you can help us protect our Websites.
`}
      </pre>
      <h2>4. DECLARATIONS</h2>
      <pre>
        {`I hereby certify that I am, and as the case may be, that Studio Models under my Account as well as any person appearing in camera area or pictures, are at least 18 years of age (or 21 when so required by local law), or age of majority in my/their legal jurisdiction (whichever is greater). I certify that I or the Studio Models, as the case may be, reached the Age of Majority and that I will not provide the RichCams with false identification (including my or Studio Models’ name, address and/or date of birth).

I am fully aware and accept that the RichCams will not be considered responsible, either jointly or severally, for any content, information, communication, opinion, expression, and/or action of any kind whatsoever which originate from the Members and/or Performers/Studio Models of the Websites or from any other person or third party to this Agreement.

I certify that all decisions related to the rendering of Services are made at my, or as the case may be, at Studio Models’ own discretion, that such Services do not violate the local community standard of ’obscenity’ in my area, nor do they violate any other laws. I am completely responsible for my own actions as a consenting adult and, as the case may be, for any actions of Studio Models. I also certify that I am, and as the case may be, that Studio Models under my Account are also aware of my/their criminal liability and therefore, I/they will act, under all circumstances, in accordance with my local community standard of morality and applicable laws. Moreover, I certify that I am not under the influence of drugs or alcohol, and that I am, and as the case may be, Studio Models are, rendering the Services/Studio Models Services in the video/stream and appearance(s) at my/their own free will.

I will indemnify and hold the RichCams harmless from all liabilities related to the Services rendered in the framework of this Agreement. I hereby discharge all persons related to the RichCams of any past, present, or future liabilities in connection with this Agreement.

I further represent that I have fully read and agree with the contents of this Agreement prior to its execution. I have not been induced or forced by the RichCams, employees, nor anyone acting on their behalf to sign this Agreement. As I accept the terms of this Agreement, I certify that the foregoing representations are true and accurate. I also certify, as a Performer, or as a Studio, as the case may be, that I or Studio Models will not provide any defamatory, abusive, threatening, racially offensive, or illegal material, and have no intention to support immoral interests. I understand that if the RichCams suspects that I, or as the case may be, Studio Models have provided forbidden content, my Account(s) will be frozen immediately for further investigation and it may result in permanent termination of my Account(s), and in the cancellation and forfeiture of all payments from the RichCams.

I also certify that I will not force anyone to render Services on Websites promoted by the RichCams and that all Studio Models under my Account, as the case may be, are providing Studio Models Services through the Websites by their own free will. I will not solicit or take part in anything related to escort services or prostitution. I declare that all my Accounts eventually created or which will eventually be created in the future under my Account will be provided with true and accurate legal documents.

I further declare that I understand that any physical contact or exchange of contact details with Members of the Websites or contacting them in any other way is prohibited. Any breach of this obligation will immediately result in termination of this Agreement.

I hereby acknowledge that the RichCams has the right to, immediately and unilaterally, terminate this Agreement and any Accounts upon the slightest suspicion of forgery or inappropriate conduct.

I also acknowledge the right for the RichCams and/or any other entity indicated by it, to be fully indemnified for all damages caused by my unlawful conduct or breach of his Agreement.

As a Studio, I declare that I will be responsible for Studio Models’ behavior and Services rendered and I will ensure that Studio Models accept and respect all the above mentioned declarations and all obligations applicable for Performers under this Agreement.
`}
      </pre>
      <h2>5. PERSONAL DATA</h2>
      <pre>{`As a Performer, I hereby allow/will be responsible for ensuring that Studio Models will allow under the Studio Model Contract all my/their personal data and/or the contents published on the Websites to be inspected by the RichCams and any entities appointed by the latter, randomly, resorting to any existing means for such effect. Notwithstanding, I am aware that it is not a duty of the RichCams to proceed with such inspection, and the RichCams and said entities will not be considered responsible, either jointly or severally, in case of my/their unlawful conduct or provision of false information to the RichCams.

I, as a Performer hereby expressly authorize/will be responsible for ensuring that Studio Models expressly authorize under the Studio Model Contract the RichCams, during the execution of this Agreement and, following termination of this Agreement, during the period necessary for the RichCams to comply with legal requirements, to collect and process my/Studio Models’ personal data (including sensitive data such as data related to religious and political beliefs, Sexual Preferences, etc.) that are provided to the RichCams under this Agreement, as well as technical data related to my/their connections to the Websites, in accordance with applicable laws, and more precisely with applicable data protection regulation.

I, as a Performer agree and authorize/will be responsible for ensuring that Studio Models agree and authorize under the Studio Model Contract the RichCams and/or any other entity appointed by it, to obtain and store information automatically from my/Studio Models’ computer used to connect to the Websites (including cookies).

I, as a Performer, hereby agree/will be responsible for ensuring that Studio Models agree and allow the RichCams to collect, process, and communicate to its processors and its group companies, including the processors that are deemed to ensure an adequate level of protection, the following types of personal data/information (including sensitive personal data as referred in the applicable data protection regulation):


Information that I/Studio Models voluntarily provide to the RichCams/Studio, such as names, e-mail address, address, date of birth, and other miscellaneous account information submitted through the Performer registration form;
Software and hardware attributes, along with any other data that can be gained from the general Internet environment, such as browser type, IP address, etc.;
Private communications, such as audio/video conversations, chat logs and e-letters to the RichCams’s staff. The RichCams keeps chat logs for a limited period of time.

The RichCams will use my/Studio Models’ personal data for mainly general purposes, such as accounting and management of suppliers, contacting me/Studio Models, customizing the environment of the Websites and for promotional marketing purposes, to the extent allowed by law.

I have been duly informed, and as a Studio I will be responsible for duly informing Studio Models, that the RichCams grants full cooperation to legal authorities and responds to court orders. I have also been duly informed, and as a Studio I will be responsible for duly informing Studio Models, that the RichCams keeps the data collected on an electronic level, being used for the purpose of this Agreement, for the performance of the Services/Studio Models Services and carrying out public interests.

I, as a Performer hereby agree, accept and consent, and as a Studio I will be responsible for ensuring that Studio Model agrees, accepts and consents, to give the explicit consent to the Company for the transfer of the copies of identity documents, model agreements and/or other relevant personal data of the Performer, should the Company be requested by the competent third-party (credit institutions, payment processing companies, government authorities, enforcement agencies, auditors etc.) if it is necessary to verify the identity of the Performer, complete the verification process in accordance with the AML, GDPR, 18 U.S. Code § 2257 Record keeping requirements and all other applicable laws, rules, regulations and requirements.

Moreover, I have been informed, and as a Studio I will be responsible for duly informing Studio Models, that the RichCams takes serious security measures to grant maximum protection to all data against unauthorized access, modification, disclosure, or deletion.

I agree and I accept, and as a Studio I will be responsible for ensuring that Studio Models agree and accept, that the RichCams stores information regarding the computer which logs into the account of the Websites (e.g.: location, IP address, browser type, used password), and even stores the login attempts.

The RichCams has assured me that its employees are bound by confidentiality obligations and that only authorized personnel will be granted access to my/Studio Models’ personal data processed by the RichCams.

I understand that I may obtain a copy of any of my personal information that the RichCams processes and ask for rectification of any incorrect personal data, upon written request to the contact provided in this Agreement and indication of the email address to which the information must be sent. If I come to oppose the processing of my personal data by the RichCams, I am entitled to terminate this Agreement at any time in compliance with its provisions.

I hereby declare that I hereby indemnify and hold the RichCams harmless in case I exercise the right of information foreseen above if my personal data is lost or subject to unauthorized access.

I, as a Studio, will be responsible and liable for informing the Studio Models that they may obtain a copy of any of their personal information that the RichCams processes and ask for rectification of any incorrect personal data, upon written request to the contact provided by the Studio under the Studio Model Contract and indication of the email address to which the information must be sent. In case of such a request by a Studio Model, the Studio will immediately inform the RichCams in writing to the contact provided in this Agreement. The Studio will be responsible and liable for informing Studio Models that if they come to oppose the processing of their personal data by the RichCams, they are entitled to terminate the Studio Models Contract at any time in compliance with its provisions.

I, as a Studio, will be responsible and liable for informing Studio Models under their Studio Model Contract that they agree to hold the RichCams harmless in case Studio Models exercise their right of information, access, and/or opposition foreseen above, and in case their personal data is lost or subject to unauthorized access.

I, as a Studio, will be responsible and liable for indemnifying, paying the costs of defense and holding the RichCams, its officers, directors, affiliates, attorneys, shareholders, managers, members, agents and employees harmless from any and all claims, losses, liabilities or expenses (including reasonable attorneys’ fees) brought by third parties arising out of or related to the processing of Studio Models’ personal data by the RichCams.

In any event, the Studio undertakes to receive all necessary consents from Studio Models and to ensure that all the rights of Studio Models in respect of their personal data described above are respected.
`}</pre>
      <h2>6. RIGHTS TO THE CONTENT (IMAGE, VIDEO, etc ) AND AUTHOR RIGHT</h2>
      <p>
        I expressly authorize, and as the case may be, I am responsible and liable for ensuring that
        Studio Models expressly authorize under the Studio Model Contract, the RichCams to monitor,
        record, and log all my/Studio Model online activities (including chat, video, e-mail, etc.)
        on the Websites (including but not limited to{" "}
        <a href="https:///RichCams.com" target="_blank" rel="noreferrer">
          RichCams.com
        </a>
        {")."}
      </p>{" "}
      <pre>{`I acknowledge and agree, and as the case may be, I am responsible and liable for ensuring that Studio Models are informed that the usage of photo, video, audio, text, or other content of the third-party during the rendering of Services requires necessary written permissions and/or licenses for commercial use and that acquiring such permissions is the sole responsibility of the Model/Studio Model. Furthermore, I am aware that RichCams can at any time at their own discretion request from Model/Studio Model such documents as evidence of having any and all necessary permissions, licenses, or similar documents.

I acknowledge and agree, and as the case may be, that I am responsible and liable to ensure that Studio Models to expressly authorize under the Studio Model Contract, that any material recorded or any original work published on the Websites (and all rights therein, including, without limitation, author rights to such recordings, works and materials) belong to and will be the sole and exclusive property of the RichCams.

I hereby expressly assign, and transfer (including the rights of Studio Models that have been transferred to me by the latter) without any additional compensation, except for fees that I receive for the Services rendered under this Agreement definitively, irrevocably, and exclusively to the RichCams, who hereby accepts, any and all existing and future author rights, trademarks, service marks, trade secrets and all other intellectual proprietary rights, or any portion thereof, of every kind and character, for the period of the validity of such intellectual property rights including any eventual renewals and extensions, throughout the world, related to the results, content, and proceeds of my/Studio Models appearance(s) (including all such appearances made to date) on the Websites and/or any other entity indicated by it, as well as any and all shows, videos, audio, chat, dialogue, acts, pictures or other uploaded original content and any and all contents which are part of the Services/Studio Models Services rendered and specially commissioned as part of an audiovisual work — including all renewals, and extensions of any and all intellectual property rights in case they are considered as such by governing authority or court of law. In this last case, the RichCams will be deemed, for all purposes, to be the holder of the rights thereof.

I agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Model Contract, that the RichCams may also use and reuse, publish, distribute, edit, excerpt, exhibit, and otherwise exploit my/Studio Models’ name (real or fictional), likeness, persona, performance, voice, pictures, chat, video, audio, uploaded original content, biological information and identification, and statements, for any and all uses, in whole or in part, in any and all media and manners now known or learned, for the use anywhere, without limitation, including in connection with advertising, exploitation, and publicity.

I agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Model Contract, that the RichCams may edit my/Studio Models’ appearance as the RichCams sees fit (and that I/Studio Models waive any and all moral rights, to the extent permitted by law, that I/Studio Models may have), and that I/Studio Models understand that the RichCams has no obligation to use my/their appearance(s).

I agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Model Contract that still pictures may be made from video or my/their appearance(s) by any means, and that I/Studio Models grant to the RichCams and any of its successors, licensees, and assignees, the right to use said photographs, without further payment to me/Studio Models, in printed publications, digitally on the Internet or any other media, without restrictions.

I grant, and as the case may be, I am responsible and liable for ensuring that Studio Models grant under the Studio Model Contract, the RichCams and its successors, licensees, and assignees, the right to use any photos taken by me/Studio Models (via webcam or by other means) and sent for publication on the Websites without further payment to me/Studio Models, in printed publications, digitally on the Internet or any other media, without restrictions.

I hereby expressly waive, and as the case may be, I am responsible and liable for ensuring that Studio Models expressly waive under the Studio Model Contract, any further financial compensation for any of the rights assigned, transferred, or granted to the RichCams under this Agreement/the Studio Model Contract.

I agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Model Contract, that the RichCams reserves the right to transfer or assign any of the above mentioned rights to its group companies or any other person if the need arises.

I hereby expressly agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Models Contract, that the RichCams may use, utilize, publish, distribute, edit and otherwise exploit all of my performer content including my uploaded original content (both nude and non-nude) related to the Website in all kinds of media sources including, but not limited to, the Internet, broadcasted media and social networking sites (e.g. X, Facebook, Instagram, etc.).`}</pre>
      <h2>7. DUTIES</h2>
      <pre>
        {`I undertake the duty to provide the RichCams with updated information concerning any changes related to my/Studio Models’ personal data, within five days from the occurrence/notification of said changes.

I undertake, and as the case may be, I am responsible and liable that Studio Models undertake the duty to renew expired documents and to provide such documents to the RichCams. I understand that without providing valid documents to the RichCams, I will not be allowed to use the services of the website to the full extent.

I, as a Performer, will not provide, and as the case may be I, as a Studio, will be responsible and liable for ensuring that Studio Models not provide, any defamatory, abusive, threatening, racially offensive, or illegal material. I will not solicit or take part, and as the case may be, I will be responsible and liable for ensuring that Studio Models not solicit or take part in anything related to escort services or prostitution, and I am fully aware of/responsible for informing the Studio Models of my/their criminal liability in such cases.

I hereby certify, and as the case may be, I am responsible and liable for ensuring that Studio Models certify under the Studio Model Contract, that I/Studio Models will not have any physical contact with the Members of the Websites, nor will I/Studio Models organize any meetings with them or in any other way try to come into contact with them other than provision of online Services/Studio Models Services under this Agreement/the Studio Model Contract.

I undertake, and as the case may be, I am responsible and liable for Studio Models to undertake the duty to obtain any and all necessary written permissions and/or licenses for commercial use of the audio, video, photo, text, and/or any other content of the third-party prior the submission, upload, transmit, live streaming, or otherwise making available the aforementioned content while rendering Services.

I undertake, and as the case may be, I am responsible and liable for Studio Models to undertake the duty to clear out of my/their recording area all objects subject to forbidden and/or abusive content, to trademark or any other intellectual property right of third parties, and to comply with legal requirements (including the ones concerning author rights) at all times, avoiding unlawful use of trademarks, brands, imagery, and/or registered music, and to comply with the terms, rules and mandatory requirements applied by the RichCams.

I undertake, and as the case may be, I am responsible and liable for Studio Models to undertake the duty, to provide to the Website the photo and/or video as well for the Performer’s Profile area as to other directories of the Websites in accordance with the law requirements and/or terms and internal rules, mandatory requirements for the Profile photo applied by the RichCams to the photo/video/audio/text content. In the event the photo and/or video were provided by the Performer for the Performer’s Profile area, it should first be approved by the RichCams and then upon the approval it could be published in the Performer’s Profile area.

Mandatory requirements for Profile photo are:


it is of good quality (it is not blurry, grainy or fuzzy);
it shows only the Performer and people perform with (no one else, not even pets);
it features the Performer (no photos of other people or anime-style depictions);
it does not contain forbidden content (underage sexual activity, bestiality, incest, demonstration of blood or animals, offensive content, suggests pedophilia, adolescence, bestiality or zoophilia, elimination or consumption of any bodily waste, link references, advertising material, logos, signatures, etc.).

I understand and agree, and as the case may be, I am responsible and liable for ensuring that Studio Models agree under the Studio Model Contract, that the RichCams is entitled at any time at his sole discretion to delete, remove, edit and adapt any photo/video/audio/text content uploaded, written, published, displayed by the Performer and/or by the Member as well in the Profile area of the Performer as in any directory on the Websites operated by the RichCams.

I will not, and as the case may be, I will be responsible and liable for ensuring that Studio Models do not, solicit, offer, purchase, sell, or propose any business deal, transaction, trade, or other economic deals to Members, since I acknowledge that the RichCams expressly forbids me/Studio Models from doing so.

I will immediately inform the RichCams of any apparent breach of security, such as loss, theft, or unauthorized disclosure or use of a username or password. I will also remain exclusively and personally liable for any unauthorized use of the Services/Studio Models Services offered through my Account.
I will indemnify, pay the costs of defense, and hold the RichCams, as well as its officers, directors, affiliates, attorneys, shareholders, agents, and assignees, harmless from any and all claims brought by third parties arising out of, or related to, my, or as the case may be, Studio Models’ conduct, statements, uploaded content or actions during any appearance(s) on the Websites (including but not limited to`}{" "}
        <a href="https:///RichCams.com" target="_blank" rel="noreferrer">
          {" "}
          RichCams.com
        </a>{" "}
        {`). The provision set forth herein includes, without limitation, any liability or damage arising from any comment, recommendation, advice, suggestion, reading, example, conclusion, or other, made by me/Studio Models, as well as for any products, services, information, or other materials displayed, purchased, or obtained by Members in connection with the Services/Studio Models Services or Performers/Studio Models suggestions.

I will fully indemnify and pay any and all costs of defense to the RichCams in case of breach of any of the clauses set forth in this Agreement.

As a Model or Studio I declare that I am responsible for ensuring to be compliant with all local laws, filing and registration obligations, including but not limited to registering to do business, filing and payment of taxes (including Sales, VAT, withholding or any other tax deemed applicable), submitting relevant tax declarations to tax authorities, and providing the RichCams and/or the Agent of RichCams and/or the Payment Agent with relevant tax/VAT IDs should I exceed the local threshold, thereby requiring registration and payment of any value added or similar tax where applicable. Upon the RichCams request I will without any delay provide confirmation of the paid taxes.

For the US Tax Residents only: I undertake the duty to complete and to return to the RichCams and/or the Payment Agent the Form W-9 (Request for Taxpayer Identification Number and Certification) within one week from the receipt of the request concerning the above named form, should my earnings during a calendar year be of $400 or more. I acknowledge and agree that the RichCams and/or the Agent of the RichCams and/or the Payment Agent have the right to suspend all payments to me if I don’t provide the completed Form W-9 at the appropriate time until the relevant data are submitted.`}
      </pre>
      <h2>8. BASIC RULES FOR THE PROVISION OF SERVICES</h2>
      <pre>
        {`The below-mentioned rules must be followed by all Performers rendering Services through the Websites. The Studios undertake to guarantee that Studio Models rendering Services on their behalf respect the rules below as well.

Performers must have a valid and approved registration on the Websites. Performer, or as the case may be, Studio Model appearing in the camera area or in the pictures through`}{" "}
        <a href="https:///richcams.com" target="_blank" rel="noreferrer">
          RichCams.com
        </a>{" "}
        must be registered under the given Account.{" "}
        {`Broadcasting a pre-recorded video or still image instead of a live camera stream is against the rules of the Websites. Violation of the rules is considered a breach of this Agreement and might result in monetary deduction, suspension of the Account, immediate ban from the Websites or the discontinuance of payouts.

Giving out and asking for personal information, including personal information of Members and Performer or Studio Models is against the rules. E-mail addresses from outside the Websites, online messenger IDs (such as: Telegram, Viber, Skype, etc.), permanent addresses, etc. are considered personal information and are forbidden to be asked and/or provided.

Any kind of personal contact with Members of the Websites, soliciting for escort service, prostitution, asking for any form of compensation, is against the rules.

Underage sexual activity, bestiality, incest, demonstration of blood or animals and mimicking the above mentioned is against the rules. Role playing that includes an underage role is against the rules. Any attempt to display text material that is a celebrity name, offensive, suggests pedophilia, adolescence, bestiality or zoophilia, referring to elimination or consumption of any bodily waste or implying words that are unacceptable by the standards of good taste will lead to immediate and permanent suspension of the concerned account.

Advertising other websites or services is strictly prohibited, unless expressly allowed by the RichCams in this Agreement.

All registered persons of an Account must be visible and recognisable in the camera area and in the pictures all the time (showing body parts only is not acceptable). Performers need to follow the basic category rules described in the Websites for each category of the Services regarding the number of persons, proper outfit, activities, etc.

In case the Performer or Studio Model, as the case may be, misled the paying Member about his/her/their gender, preferences, or activities, the Member is entitled to claim back any amounts paid. Promising Members certain performances for their tips or paid shows (private, fully private, group) and not acting on these promises after being tipped is also considered misleading and may result in returning any amounts received. Rejecting members’ offers or requests (either in free or paid chats) is not considered misleading.

Sleeping in the camera area is prohibited.

You/Studio Models have the option to remove, expel, eject or ban impolite Members from your/their chat room, although it is against the rules to misuse this privilege. Making derogatory statements about other Performers/Studio Models or Members is prohibited. Performers/Studio Models are obliged to respect and follow the instructions of the Customer Service staff. If you need help, do not hesitate to contact our Customer Service.

In case of offense or violation of the rules, the Performer would be first warned by the RichCams. However, in case of the egregious violation (e.g. advertising of other webcam websites and/or referring to direct links) the RichCams reserves the right to fine the Performer immediately without giving a prior warning. The RichCams reserves the right to fine the Performer in the case of offense, with the amount to be fined depending on the nature of such offense. The RichCams is entitled to deduct such fines from any payments due to the Performer in accordance with the fine system listed below:`}
      </pre>
      <table>
        <tr>
          <td>Leaving the camera image (being out of camera view) more than 5 minutes</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>Broadcasting alone on couple accounts in Free chat</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>Sleeping or passing out (Performer or/and partner)</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>
            Exchanging, giving out, asking for or accepting personal/contact information (On such
            proposals, the Performer should refuse to provide/accept personal information or ignore
            the request.)
          </td>
          <td>$25</td>
        </tr>
        <tr>
          <td>Misleading about gender</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>Animals/pets in the broadcast area</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>Publishing of links to other Internet resources</td>
          <td>$25</td>
        </tr>
        <tr>
          <td>
            Sending own referral link to RichCams members/guests in Free/Private Chat (it is
            prohibited to spam by own referral link)
          </td>
          <td>$25</td>
        </tr>
        <tr>
          <td>
            Referring to other live adult websites by text material, logos, pictures, videos etc.
          </td>
          <td>$100</td>
        </tr>
        <tr>
          <td>Asking for and/or receipt of the payment from the members outside the website</td>
          <td>$100</td>
        </tr>
        <tr>
          <td>
            Referring, advertising, propaganda and/or promotion of other live adult websites by
            Performer by sending/ broadcasting or posting their links on the website
          </td>
          <td>$100</td>
        </tr>
        <tr>
          <td>Being online with inappropriate camera quality</td>
          <td>suspension of broadcasting</td>
        </tr>
      </table>
      <p>
        The RichCams has zero-tolerance regarding the violations listed below and{" "}
        <b>will close the Performer’s account</b> should mentioned violations occur
      </p>
      <table>
        <tr>
          <td>Model presenting as minor</td>
          <td style={{ minWidth: "160px" }}>closure of account</td>
        </tr>
        <tr>
          <td>Minors, children, babies on camera or in the same room</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Urination, defecation, “going to the bathroom”, enema play, Breastfeeding</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Consumption of any bodily waste, vomiting</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Menstrual bleeding</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Illegal drugs, or drugs that may be perceived as illegal in other locations</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Excessive consumption of alcohol or other legal or prescribed medicines or narcotics
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Incest (sexual relations involving family members)</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Broadcasting a pre-recorded video or still image instead of live camera stream</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Violence, blood, torture, pain, erotic asphyxiation, or any actions associated with
            bringing harm to yourself in any way
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Discussing or arranging prostitution or escort services</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Arranging other forms of employment with Website users / visitors</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Bestiality, or animals/pets on camera in a sexual or provocative context (zoophilia)
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Discussing or arranging pedophilia, adolescence</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Unregistered person in the broadcast area</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Using the Model account of the other person</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Role playing that includes an underage role, incest, violence, cruelty, blood, torture,
            pain, erotic asphyxiation, or any actions associated with bringing harm
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Fraudulent actions concerning the earnings of the Performer (including but not limited
            to unfair winning in contests and awards, dishonest gain, breach of the Model’s /
            Studio’s or Member’s accounts, participation in fraudulent schemes with stolen/bogus
            credit cards, accompliceship in covins against other Performers and/or website and/or
            RichCams, etc.)
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Accepting/asking for personal meeting/contact with Members</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Actions causing financial damage and/or impairment of goodwill of the RichCams</td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Frivolous and/or disrespectful attitude toward the website and/or the website
            Administration and/or its staff on the website and/or on the third parties media
            resources (websites, forums, chats, blogs, social medias, TV, radio, billboards, etc.)
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            Constant disregard of the warnings received from the Administration of the website
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>
            The Performer’s identity doesn’t comply with the ID documents previously provided to the
            website
          </td>
          <td>closure of account</td>
        </tr>
        <tr>
          <td>Using fake IDs for the account registration on the website</td>
          <td>closure of account</td>
        </tr>
      </table>
      <pre>{`Please be advised that the RichCams reserves the right to suspend and/or to close the Model’s/Studio’s Account in case of repeated violations by the Performer of the rules.

This basic list of rules is non-exclusive and does not contain all possible violations of this Agreement. Therefore, an act not mentioned above may still qualify as a breach of this Agreement when taking into consideration the situation and analyzing the general intent of this Agreement. In these cases the RichCams is also entitled to impose the fine on the Performer and/or to suspend the Model’s/Studio’s Account subsequently giving to the Model /Studio Model the notice containing grounds for the implemented fine.`}</pre>
      <h2>9. ASSIGNMENT</h2>
      <pre>{`I am not entitled to assign or transfer my contractual position to any other person/entity. I agree, and as the case may be, I will be responsible and liable for ensuring that Studio Models agree that my/Studio Models username/password must not be provided to any other person, otherwise I will be held liable in accordance with “7. Duties“ and “8. Basic rules for the provision of services” of this Agreement.

I agree that the RichCams has the right to transfer or assign its rights and obligations under this Agreement without limitation.`}</pre>
      <h2>10. AMENDMENTS</h2>
      <pre>{`The RichCams may make changes to this Agreement, but undertake the duty to inform me of such changes before they enter into force. In case the RichCams makes changes to this Agreement, the Studio undertakes the duty to reflect such changes in the Studio Model Contract, to ensure that the Studio Model Contract remains compliant with the rights and obligations of the Studio under the Agreement.

The last revision of this Agreement is indicated at the top of this document and takes precedence over all previous versions.

If any modification is unacceptable to you, you or as the case may be, Studio Models may cease providing Services to the RichCams or terminate this Agreement. If you, or as the case may be, Studio Models do not cease rendering Services, you will be conclusively deemed to have accepted the changes.`}</pre>
      <h2>11. TERMINATION</h2>
      <pre>
        Either party may terminate this Agreement, at any time, for any or no reason, upon prior and
        express notice given in writing (including via email) to the other party with a minimum
        antecedence of five business days. The RichCams may terminate this Agreement immediately in
        the case of serious violation of its terms by the counterparty, without prior notice
      </pre>
      <h2>12. LIMITATION OF THE LIABILITY</h2>
      <pre>
        {`The RichCams bears all responsibilities to you (Performer/ Model/ Studio/ Studio Model) with regard to or arising from or in connection with this Agreement.

Under no circumstances will the RichCams, their employees, the RichCams’s independent contractors, authors, representatives, assignees, and successors will be, to the extent permitted by law, liable to you (Performer/ Model/ Studio/ Studio Model) for any direct or indirect losses, injuries, or incidental or consequential damages of any kind (including, without limitation, damages for loss, unauthorized access to and/or disclosure by third party of personal data, the loss of business profits, business interruption, loss of business information, other incidental or consequential damages, or any other pecuniary loss) with regard to or arising from or in connection with this Agreement, or due to any mistakes, omissions, delays, errors, interruptions in the transmission, or in regard with the activity of the RichCams, its services and websites, content or program materials, including, without limitation, any losses due to server problems, hacker attacks, cyber fraud or due to incorrect placement of HTML or other programming languages.

The RichCams is not responsible for damages and/or loss of the data of yours (Performer/ Model/ Studio/ Studio Model) resulting from the hacker attacks any other attacks and/or unlawful acts directed against the website `}
        <a href="https:///richcams.com" target="_blank" rel="noreferrer">
          richcams.com
        </a>
        ,{" "}
        <a href="https:///start.richcams.com" target="_blank" rel="noreferrer">
          start.richcams.com
        </a>{" "}
        and/or any other website which may be operated by the RichCams and/or its successor.
      </pre>
      <h2>13. SEVERABILITY</h2>
      <pre>
        If any term or provision of this Agreement will be held invalid or unenforceable to any
        extent under any applicable law by court of competent jurisdiction, the remainder of this
        Agreement will not be affected thereby, and each remaining term and provision of this
        Agreement will be valid and enforceable to the fullest extent permitted by law.
      </pre>
      <h2>14. APPLICABLE LAW AND JURISDICTION</h2>
      <pre>
        The parties will irrevocably submit to the exclusive jurisdiction of the courts of Estonia
        for the purposes of hearing and determining any dispute arising out of this Agreement. This
        Agreement, all matters arising from it, and any dispute resolutions referred to the above
        will be governed by, and construed in accordance with the laws of Estonia, notwithstanding
        conflict of law provisions and other mandatory legal provisions.
      </pre>
      <h2>15. NOTICES</h2>
      <pre>
        {`Any notice, recommendation, or advice by each party to the other hereunder will be provided to the following contacts:

RichCams: `}{" "}
        <a href="mailto: support@richcams.com ">support@richcams.com </a>
      </pre>
      <p>
        Performer/Studio: Contacts provided in the sign-up/registration form, or subsequently
        amended.
        <br />
        <br />
        <b>
          I have carefully read the terms of this Agreement and I expressly declare the acceptance
          of Privacy Policy and this Agreement.
        </b>
      </p>
    </>
  );

  return isSection ? (
    <section className="text-page">
      <SomeText />
    </section>
  ) : (
    <SomeText />
  );
}
