import React, { useState } from "react";
import imgLocation from "../../images/icons/location.svg";
import imgMail from "../../images/icons/mail.svg";
import imgTg from "../../images/logo/logos_telegram.svg";
import ApproveModal from "./ApproveModal";
import { useTranslation } from "react-i18next";
import Cookie from "./cookie/Cookie";
import Policy from "./policy/Policy";
import P18usc2257 from "./P18usc2257";
import Terms from "./terms/Terms";
import Dmca from "./dmca/Dmca";

const termsOfService = `Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.`;

const approveTexts = {
  service: { title: "Terms of service", text: <Terms /> },
  privacy: { title: "Privacy polices", text: <Policy /> },
  cookies: { title: "Cookies policy", text: <Cookie /> },
  P18usc2257: { title: "18 U.S.C 2257", text: <P18usc2257 /> },
  dmca: { title: "DMCA / Remove Content", text: <Dmca /> },
};

export default function HomeFooter() {
  const { t } = useTranslation();

  const [openTypeApprove, setOpenTypeApprove] = useState(false);

  const titleModal = approveTexts[openTypeApprove]?.title;
  const textModal = approveTexts[openTypeApprove]?.text;

  return (
    <>
      <div className="home__footer">
        <div className="home__footer__content">
          <div className="home__footer__center">
            <div className="home__footer__actions">
              <button onClick={() => setOpenTypeApprove("service")}>{t("Terms of service")}</button>
              <button onClick={() => setOpenTypeApprove("privacy")}>{t("Privacy polices")}</button>
              <button onClick={() => setOpenTypeApprove("cookies")}>{t("Cookies policy")}</button>
            </div>
            <div className="home__footer__actions__two">
              <span className="home__footer__copyright">
                {" "}
                {t(`©${new Date().getFullYear()} RichCams.com`)}
              </span>
              <button onClick={() => setOpenTypeApprove("dmca")}>
                {t("DMCA / Remove Content")}
              </button>
              <button onClick={() => setOpenTypeApprove("P18usc2257")}>
                {t("18 U. S. C. 2257")}
              </button>
            </div>
          </div>
          <div className="home__footer__right">
            <span>{t("For any questions write to:")}</span>
            <div className="home__footer__connection">
              <a target="_blank" rel="noreferrer" href={"https://t.me/richCams"} className="action">
                <img alt="tg" src={imgTg} /> @richCams.com
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto: streamer@richcams.com"
                className="action"
              >
                <img alt="mail" src={imgMail} /> streamer@richcams.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <ApproveModal
        setOpen={setOpenTypeApprove}
        isOpen={!!openTypeApprove}
        title={titleModal}
        text={textModal}
        type={openTypeApprove}
      />
    </>
  );
}
